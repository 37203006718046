import { Routes } from '@angular/router';
import { DownloadAppComponent } from './pages/standalone/download-app/download-app.component';
import { Constant } from './pages/general/blog/constant';
import { MaintenanceComponent } from './pages/standalone/maintenance/maintenance.component';
import { NotFoundComponent } from './pages/standalone/notFound/notFound.component';
import { IntroduceRoboComponent } from './pages/standalone/introduce-robo/introduce-robo.component';
import { IntroduceRewardComponent } from './pages/standalone/introduce-reward/introduce-reward.component';
import { IntroduceAgencyComponent } from './pages/standalone/introduce-agency/introduce-agency.component';
import { ReloadPageComponent } from './pages/standalone/reload-page/reload-page.component';
import { CafeFundMarketing } from './pages/general/markerting/cafe-fund/cafe-fund';
import { IntroduceFmarketMarketing } from './pages/general/markerting/introduce-fmarket/introduce-fmarket';
import { ProgramSharedGoalMarketing } from './pages/general/markerting/program-shared-goal/program-shared-goal';
import { ReferralProgramMarketing } from './pages/general/markerting/referral-program/referral-program';
import { RobofSmartPortfolioMarketing } from './pages/general/markerting/robof-smart-portfolio/robof-smart-portfolio';
import { SharedGoalMarketing } from './pages/general/markerting/shared-goal/shared-goal';
import { SmartPortfolioMarketing } from './pages/general/markerting/smart-portfolio/smart-portfolio';
import { WhatisOpenedFundMarketing } from './pages/general/markerting/what-is-opened-fund/what-is-opened-fund';
import { SmartPortfolioComponent } from './pages/standalone/smart-portfolio/smart-portfolio.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'account/investor/ftool',
    redirectTo: '/ftool',
    pathMatch: 'full',
  },
  { path: Constant.path_download_app, component: DownloadAppComponent},
  { path: Constant.path_account, loadChildren: () => import('./pages/general/account/account.module').then(m => m.AccountModule) },
  { path: Constant.path_auth, loadChildren: () => import('./pages/general/authorization/authorization.module').then(m => m.AuthorizationModule) },
  { path: Constant.path_active, loadChildren: () => import('./pages/general/active-mail/active-mail.module').then(m => m.ActiveMailComponentModule) },
  { path: Constant.path_share, loadChildren: () => import('./pages/general/share-public/share-public.module').then(m => m.SharePublicModule) },

  { path: '', 
    children: [
      { path: Constant.path_markerting_cafe_fund, component: CafeFundMarketing },
      { path: Constant.path_markerting_introduce_fmarket, component: IntroduceFmarketMarketing },
      { path: Constant.path_markerting_program_shared_goal, component: ProgramSharedGoalMarketing },
      { path: Constant.path_markerting_referral_program, component: ReferralProgramMarketing },
      { path: Constant.path_markerting_robof_smart_portfolio, component: RobofSmartPortfolioMarketing },
      { path: Constant.path_markerting_shared_goal, component: SharedGoalMarketing },
      { path: Constant.path_markerting_smart_portfolio, component: SmartPortfolioMarketing },
      { path: Constant.path_markerting_what_is_opened_fund, component: WhatisOpenedFundMarketing },
    ]
  },
  
  { path: Constant.path_smart_portfolio, component: SmartPortfolioComponent },
  { path: 'reload', component: ReloadPageComponent },
  { path: Constant.path_503, component: MaintenanceComponent },
  { path: Constant.path_404, component: NotFoundComponent },
  { path: 'robo/introduce', component: IntroduceRoboComponent},
  { path: 'reward/:code', component: IntroduceRewardComponent},
  {
    path: 'help-center',
    loadChildren: () => import('./pages/general/help-center/helpcenter.module')
      .then(mod => mod.HelpcenterModule)
  },
  { path: "refpartner/:code", component: IntroduceAgencyComponent},
  { path: "ref-partner/:refCode", component: IntroduceAgencyComponent},
  {
    path: '',
    loadChildren: () => import('./pages/general/dashboard/dashboard.module')
      .then(mod => mod.DashboardModule)
  },
  { path: '**', component: NotFoundComponent }
];