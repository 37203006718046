import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Constant } from '../../../pages/general/blog/constant';
import { BlogService } from '../../../pages/general/blog/blog.service';
import { environment } from '../../../../environments/environment';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
} from '@angular/router';
const MENUS = {
  HOME: [Constant.path_home],
  PROCDUCT: [
    Constant.path_landing_page_product,
    Constant.path_funds_page,
    Constant.path_gold_home,
    Constant.path_detail_fund,
    Constant.path_detail_gold,
  ],
  MARKET: [
    Constant.path_bank_interest_rate,
    Constant.path_performance_fund,
    Constant.path_calculate_invest,
  ],
  BLOG: [Constant.path_blog],
  ABOUT: [
    Constant.path_our_partner,
    Constant.path_our_story,
    Constant.path_detail_partner,
    Constant.path_blog_category_partner,
    Constant.path_fqs,
  ],
};
import { MenuService } from '../../../pages/general/blog/services/menu.service';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { EventManagerService } from '../../../pages/general/blog/event-manager.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

/**
 * declare Jquery
 * */
declare let $: any;

@Component({
  selector: 'header-home-page',
  standalone: true,
  imports: [RouterModule, CommonModule, HttpClientModule, TranslateModule],
  providers: [],
  templateUrl: './header-home-page.html',
  styleUrls: ['./header-home-page.scss', './header-home-page-responsive.scss'],
})
export class HeaderHomePage implements AfterViewInit, OnInit {
  subscriptions?: Subscription[] = [];
  user: any;
  theme: any;
  Constant = Constant;
  isDropdown: any = false;
  categories: any[] = [];
  linkBlog: string = '';
  url: string = '';
  firstPath: string = '';
  language: string = '';
  MENUS = MENUS;
  MENUS_ACTIVE: any = {
    HOME: false,
    PROCDUCT: false,
    MARKET: false,
    BLOG: false,
    ABOUT: false,
  };
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private cdref: ChangeDetectorRef,
    private http: HttpClient,
    private translate: TranslateService,
    private eventManager: EventManagerService,
    public menuService: MenuService
  ) {}

  ngOnInit() {
    this.linkBlog = environment.baseHref;
    if (typeof window !== 'undefined') {
      this.url = window.location.pathname;
      this.checkAll();
    }
    this.router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this.url = route.urlAfterRedirects;
        this.checkAll();
      }
    });
    this.subscriptions = [
      this.eventManager.subscribe('data-categories', (res: any) => {
        this.categories = res.content;
        this.cutCategories();
      }),
      this.eventManager.subscribe('change-language', (res: any) => {
        this.language = (res.content.language || 'VI').toLowerCase();
        this.translate.use(this.language);
      }),
    ];
    this.getCategories();
  }
  ngAfterViewInit() {
    if (typeof window !== 'undefined') {
      this.language = localStorage.getItem('language') || '';
      $(window).scroll((e: any) => {
        let scrollY = window.scrollY;
        if (scrollY > 85) {
          $('#header').addClass('box-shadow');
        } else {
          $('#header').removeClass('box-shadow');
        }
      });
    }
  }
  getCategories() {
    this.getAPICategories().subscribe((res: any) => {
      const categories = res.data || [];
      const index = categories.findIndex((category: any) => {
        return category.path === 'tin_ve_doi_tac';
      });
      if (index > -1) {
        categories.splice(index, 1);
      }
      this.eventManager.broadcast({
        name: 'data-categories',
        content: categories,
      });
    });
  }
  getAPICategories() {
    return this.http.get(environment.urlBackEnd + '/blog/categories');
  }
  cutCategories() {
    this.categories = this.categories.slice(0, 4).map((category: any) => {
      MENUS.BLOG.push(category.path);
      return {
        name: category.name,
        path: category.path,
      };
    });
    this.checkAll();
    this.cdref.detectChanges();
  }

  toggle() {
    this.isDropdown = !this.isDropdown;
  }

  navigate(a: string) {
    window.location.href = a;
  }
  checkAll() {
    const split = this.url.split('/');
    this.firstPath = (split[split.length - 1] || '').split('?')[0] || '';
    this.MENUS_ACTIVE.HOME = this.checkActive(MENUS.HOME);
    this.MENUS_ACTIVE.PROCDUCT = this.checkActive(MENUS.PROCDUCT);
    this.MENUS_ACTIVE.MARKET = this.checkActive(MENUS.MARKET);
    this.MENUS_ACTIVE.BLOG = this.checkActive(MENUS.BLOG);
    this.MENUS_ACTIVE.ABOUT = this.checkActive(MENUS.ABOUT);
  }
  checkActive(menu: any) {
    return menu.some((url: string) => this.firstPath === url);
  }
  changeLanguage(language: any) {
    this.language = language;
    this.eventManager.broadcast({
      name: 'change-language',
      content: {
        language,
      },
    });

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { language: language },
      queryParamsHandling: 'merge',
    });
  }
}
