import {
  Component,
  Input,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import { EventManagerService } from '../../../pages/general/blog/event-manager.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare let $: any;
@Component({
  selector: 'confirm-router-popup',
  templateUrl: './confirm-router-popup.component.html',
  styleUrls: ['./confirm-router-popup.component.scss']
})
export class ConfirmRouterPopupComponent implements OnInit, AfterViewInit {
  @Input() url: string = '';
  constructor(
    private eventManager: EventManagerService,
    private router: Router,
    private ngbModal: NgbModal
  ) {}
  ngOnInit() {
    
  }
  ngAfterViewInit() {
   
  }
  goToNextPage() {
    const url = localStorage.getItem('nextURL');
    localStorage.setItem('confirmNextUrl', 'true');
    this.ngbModal.dismissAll();
    this.router.navigate([url]);
  }
  dismiss() {
    this.ngbModal.dismissAll();
  }
}
