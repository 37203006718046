<div class="standalone_page">
	<div class="standalone_page__header">
		<div class="img-header">
			<a href="home"><img src="https://fma.dev.techland.link/trade/assets/images/logo/fmarket-white.svg" class="" alt="fmarket"></a>
		</div>
	</div>
	<div class="standalone_page__body">
		<div class="container">
			<div class="standalone_page__body__content">
				<div class="app__background">

				</div>
				<div class="app__introduce">
					<img class="desktop" src="https://fma.dev.techland.link/trade/assets/images/logo/Logo-fmarket.png" alt="logo fmarket">

					<span class="desktop mt-4">
						{{'Ứng dụng đầu tư quỹ mở hàng đầu Việt Nam'}},
					</span>
					<span class="no-wrap desktop">{{'được Ủy ban Chứng khoán Nhà nước cấp phép ngày 02/04/2018.'}}</span>

					<span class="mobile mt-5">
						{{'Ứng dụng đầu tư quỹ mở hàng đầu Việt Nam, được Ủy ban Chứng khoán Nhà nước cấp phép ngày 02/04/2018.'}}
					</span>

					<div class="group_qr desktop d-flex">
						<div class="d-flex align-items-center flex-column">
							<span class="group_qr-img">
								<img src="https://fma.dev.techland.link/trade/assets/images/homepage/qr-download.png" alt="down app">
							</span>
							<a class="mt-3" [href]="linkRegister">{{'Đăng ký'}}</a>
						</div>
						
						<div class="group_qr-title">
							<label>Quét để tải ứng dụng</label>
							<span>iOS & Android</span>
						</div>
					</div>

					<div class="mobile">
						<p class="pb-2">Tải App để trải nghiệm tốt hơn
						</p>

						<div class="btn-app">
							<a [href]="Constant.IOS_APP" title="Fmarket Appstore ">
								<img src="https://fma.dev.techland.link/trade/assets/images/homepage/footer-app.svg" alt="appstore-fmarket">
							</a>
							<a [href]="Constant.ANROID_APP" title="Fmarket CH Play">
								<img src="https://fma.dev.techland.link/trade/assets/images/homepage/footer-google.svg" alt="chplay-fmarket">
							</a>
						</div>

					</div>



					<a class="mobile mt-3"  [href]="linkRegister">{{'Đăng ký'}}</a>
				</div>
			</div>

		</div>
	</div>

	<span class="standalone_page__footer">© 2018 FINCORP JSC. ALL RIGHTS RESERVED</span>

</div>