import { ApplicationConfig, importProvidersFrom  } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { TranslateLoader, TranslateModule, TranslateService, TranslateStore } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const appConfigTranslate: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    TranslateStore,
    TranslateService,
    importProvidersFrom(
      [
        TranslateModule.forRoot({
          loader: {
              provide: TranslateLoader,
              useFactory: httpTranslateLoader,
              deps: [HttpClient]
          } 
        }),
        TranslateStore,
        TranslateService, 
      ]
    )
  ]
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
  ]
};