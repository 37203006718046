<section class="transaction table-products">
  <div class="" [ngClass]="isDevice ? 'p-0' : 'container'">
    <div class="row table__header"></div>

    <div class="full-width" [ngClass]="isGenerateImageUpload ? 'fix-table-width' : ''">
      <div class="table-style" [ngClass]="!isViewSummary ? 'ccq-gd-market' : 'ccq-gd-home'">
        <div id="product-table">
          <div class="table__product isDesktop">
            <table class="table product-table_header" >
              <!-- Table header on desktop  -->
              <thead class="pr-head">
                <tr>
                  <th class="w-33" [ngClass]="isGenerateImageUpload ? 'fix-column-fund-name' : ''">{{'CCQ/Loại quỹ' | translate}}</th>
                  <th class="show-desk" *ngIf="!isGenerateImageUpload">{{'Tổ chức phát hành' | translate}}</th>
                  <th class="text-right" [ngClass]="isGenerateImageUpload ? 'fix-column-fund-name' : ''">{{'Giá gần nhất' | translate}}</th>

                  <th *ngIf="isFToolPage" [style.width]="!isFToolPage ? '19%' : '15%'">
                    <div class="flexbox-all">
                      <span class="text-right m-2 column-month">
                         {{'Lợi nhuận 6 tháng' | translate}}<br class="hrz_break" />
                        {{'gần nhất' | translate}}
                      </span>
                    </div>
                  </th>

                  <th  [style.width]="isGenerateImageUpload ? 'auto' : !isFToolPage ? '19%' : '15%'" >
                    <div class="flexbox-all">
                      <span class="text-right m-2 column-month">
                        {{columnMonth.name | translate}}<br class="hrz_break" />
                        {{'gần nhất' | translate}}
                      </span>
                      <div class="d-flex flex-column nag-icon" *ngIf="!isFToolPage">
                        <span
                          (click)="sort(columnMonth.field, 'DESC')"
                          *ngIf="searchParams.sort?.key !== columnMonth.field || (searchParams.sort?.key === columnMonth.field)"
                        >
                          <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 5L4.37114e-07 -2.22545e-07L8 4.76837e-07L4 5Z" fill="#B9B9B9" />
                            <path
                              *ngIf="searchParams.sort?.key === columnMonth.field && searchParams.sort?.value === 'DESC'"
                              d="M4 5L4.37114e-07 -2.22545e-07L8 4.76837e-07L4 5Z"
                              fill="#0368ff"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </th>
               
                  <th *ngIf="!isGenerateImageUpload" [style.width]="!isFToolPage ? '21%' : '15%'">
                    <div class="flexbox-all">
                      <span class="text-right m-2"
                        >{{'LN bình quân hàng năm' | translate}}<br />{{'(Theo 3 năm)' | translate}}
                      </span>
                      <div class="d-flex flex-column nag-icon" *ngIf="!isFToolPage">
                        <span
                          (click)="sort('annualizedReturn36Months', 'DESC', false)"
                          *ngIf="searchParams.sort?.key !== 'annualizedReturn36Months' || (searchParams.sort?.key === 'annualizedReturn36Months')"
                        >
                          <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 5L4.37114e-07 -2.22545e-07L8 4.76837e-07L4 5Z" fill="#B9B9B9" />
                            <path
                              *ngIf="searchParams.sort?.key === 'annualizedReturn36Months' && searchParams.sort?.value === 'DESC'"
                              d="M4 5L4.37114e-07 -2.22545e-07L8 4.76837e-07L4 5Z"
                              fill="#0368ff"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </th>
                  <th class="text-right" *ngIf="!isGenerateImageUpload" ></th>
                </tr>
              </thead>

              <!-- Table body for desktop  -->
              <tbody>
                <tr *ngFor="let item of products">
                  <td [ngClass]="isGenerateImageUpload ? 'fix-column-fund-name' : ''">
                    <div class="wrap-text d-flex align-items-center">
                      <img
                        [src]="item.ownerAvatarUrl !== null ? item.ownerAvatarUrl : '' "
                        loading="lazy"
                        width="32"
                        height="32"
                        [ngClass]="item.ownerAvatarUrl === null ? 'hidden' : ''"
                        alt="logo-issuer"
                        class="logo-issuer"
                      />
                      <div class="d-flex flex-column">
                        <div
                          class="d-flex flex-row active-link"
                          (click)="chooseProduct(item)"
                          style="cursor: pointer"
                          [ngClass]="{'active': selectedItem == item}"
                        >
                          <span class="font-bold">{{item.shortName}}</span>
                          <label id="label-ipo" class="ml-2" *ngIf="item.isProductIpo">IPO</label>
                          <label id="label-new-product" class="ml-2" *ngIf="item.isNewProduct">New</label>
                        </div>
                        <span class="asset-type"> {{(item.assetTypeName || '_')  | translate}}</span>
                      </div>
                    </div>
                  </td>
                  <td class="show-desk" *ngIf="!isGenerateImageUpload">{{item.ownerShortName}}</td>
                  <td class="text-right" [ngClass]="isGenerateImageUpload ? 'fix-column-fund-name' : ''">
                    {{item.extra?.currentNAV ? (item.extra?.currentNAV | number) : 'N/A'}}
                    <p class="no-mrg notice-time" *ngIf="!item.isProductIpo">
                      {{'Theo NAV tại' | translate}} {{item.extra?.lastNAVDate ? (item.extra?.lastNAVDate | date: 'dd/MM') :
                      '--/--'}}
                    </p>
                  </td>

                  <td class="text-center" *ngIf="isFToolPage">
                    <span
                      [ngClass]=" item.productNavChange.navTo6Months === null ? '' :
                    item.productNavChange.navTo6Months > 0 ? 'up' : item.productNavChange.navTo6Months === 0 ? 'pledge' : 'down' "
                    >
                      {{item.productNavChange.navTo6Months !== null ? (item.productNavChange.navTo6Months |
                      number) + ' %' : '_'}}
                    </span>
                  </td>

                  <td class="text-center">
                    <span
                      [ngClass]=" item.productNavChange[columnMonth.field] === null ? '' :
                    item.productNavChange[columnMonth.field] > 0 ? 'up' : item.productNavChange[columnMonth.field] === 0 ? 'pledge' : 'down' "
                    >
                      {{item.productNavChange[columnMonth.field] !== null ? (item.productNavChange[columnMonth.field] |
                      number) + ' %' : '_'}}
                    </span>
                  </td>
                  <!-- LN bình quân hàng năm (3 năm gần nhất) -->
                  <td
                    style="width: 8%"
                    class="text-center"
                    *ngIf="!isGenerateImageUpload"
                    [ngClass]=" item.productNavChange?.annualizedReturn36Months === null ? '' :
                item.productNavChange?.annualizedReturn36Months > 0 ? 'up' : item.productNavChange?.annualizedReturn36Months === 0 ? 'pledge' : 'down' "
                  >
                    {{item.productNavChange?.annualizedReturn36Months !== null ?
                    (item.productNavChange?.annualizedReturn36Months | number) + '%' : '_'}}
                  </td>
                
                  <td class="text-right" *ngIf="!isGenerateImageUpload">
                    <a [href]="item.linkBuy" class="btn btn--blue btn--mua">{{'Mua'  | translate}}</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="table__product isMobile">
            <table class="table product-table_header">
              <!-- Table header for mobile  -->
              <thead class="pr-head">
                <tr>
                  <th class="">{{'Tên quỹ' | translate}}</th>
                  <th class="mobile-column column-month text-center">
                    <div class="flexbox-all">
                      <span style="text-align: right"> {{columnMonth.name}} <br />{{'gần nhất' | translate}}</span>
                      <div class="d-flex flex-column nag-icon" *ngIf="!isFToolPage">
                        <span
                          (click)="sort(columnMonth.field, 'DESC')"
                          *ngIf="searchParams.sort?.key !== columnMonth.field || (searchParams.sort?.key === columnMonth.field)"
                        >
                          <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 5L4.37114e-07 -2.22545e-07L8 4.76837e-07L4 5Z" fill="#B9B9B9" />
                            <path
                              *ngIf="searchParams.sort?.key === columnMonth.field && searchParams.sort?.value === 'DESC'"
                              d="M4 5L4.37114e-07 -2.22545e-07L8 4.76837e-07L4 5Z"
                              fill="#0368ff"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </th>
                  <th 
                    class="mobile-column column-month text-right"
                    colspan=" 2"
                    style="width: 40%"
                  >
                    <div class="flexbox-all">
                      <span>{{'LN bình quân' | translate}}<br />   {{'hàng năm' | translate}}<br /> {{'(theo 3 năm)' | translate}}</span>
                      <div class="d-flex flex-column nag-icon" *ngIf="!isFToolPage">
                        <span
                          (click)="sort('annualizedReturn36Months', 'DESC')"
                          *ngIf="searchParams.sort?.key !== 'annualizedReturn36Months' || (searchParams.sort?.key === 'annualizedReturn36Months')"
                        >
                          <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 5L4.37114e-07 -2.22545e-07L8 4.76837e-07L4 5Z" fill="#B9B9B9" />
                            <path
                              *ngIf="searchParams.sort?.key === 'annualizedReturn36Months' && searchParams.sort?.value === 'DESC'"
                              d="M4 5L4.37114e-07 -2.22545e-07L8 4.76837e-07L4 5Z"
                              fill="#0368ff"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>

              <!-- Table body for mobile  -->
              <tbody>
                <tr *ngFor="let item of products">
                  <td colspan="4">
                    <table class="sub-table">
                      <tbody>
                        <tr>
                          <td class="" style="width: 42%">
                            <div class="wrap-text d-flex align-items-center">
                              <img
                                loading="lazy"
                                [src]="item.ownerAvatarUrl !== null ? item.ownerAvatarUrl : '' "
                                [ngClass]="item.ownerAvatarUrl === null ? 'hidden' : ''"
                                alt="logo-issuer"
                                class="logo-issuer"
                              />
                              <div style="display: flex; flex-direction: column">
                                <div
                                  class="active-link font-bold"
                                  style="display: flex; flex-direction: row"
                                  (click)="chooseProduct(item)"
                                  [ngClass]="{'active': selectedItem == item}"
                                >
                                  <span>{{item.shortName}}</span>
                                  <label id="label-ipo" class="ml-2" *ngIf="item.isProductIpo">IPO</label>
                                  <label id="label-new-product" class="ml-2" *ngIf="item.isNewProduct">New</label>
                                </div>
                                <span class="asset-type" *ngIf="isDevice"> {{(item.assetTypeName || '_')  | translate}}</span>
                                <span class="no-mrg notice-time" *ngIf="!item.isProductIpo">
                                  {{'Theo NAV tại'}} {{item.extra?.lastNAVDate ? (item.extra?.lastNAVDate | date:
                                  'dd/MM') : '--/--'}}
                                </span>
                              </div>
                              <!-- <span class="asset-type isMobile"> {{item.assetTypeName || '_'}}</span>
                              <span class="no-mrg notice-time" *ngIf="!item.isProductIpo">
                                {{'Theo NAV tại'}} {{item.extra?.lastNAVDate ? (item.extra?.lastNAVDate | date: 'dd/MM')
                                : '--/--'}}
                              </span> -->
                            </div>
                          </td>
                          <td class="text-center" style="width: 20%">
                            <span
                              class="mobile-col text-center"
                              [ngClass]=" item.productNavChange[columnMonth.field] === null ? '' :
                          item.productNavChange[columnMonth.field] > 0 ? 'up' : item.productNavChange[columnMonth.field] === 0 ? 'pledge' : 'down' "
                            >
                              {{item.productNavChange[columnMonth.field] !== null ?
                              (item.productNavChange[columnMonth.field] | number) + '%' : '_'}}
                            </span>
                          </td>

                          <td class="text-right" style="width: 38%">
                            <div
                              class="d-flex align-items-center justify-content-between"
                            >
                              <span
                                class="mobile-col text-center ml-3"
                                [ngClass]=" item.productNavChange?.annualizedReturn36Months === null ? '' :
                          item.productNavChange?.annualizedReturn36Months > 0 ? 'up' : item.productNavChange?.annualizedReturn36Months === 0 ? 'pledge' : 'down' "
                              >
                                {{item.productNavChange?.annualizedReturn36Months !== null ?
                                (item.productNavChange?.annualizedReturn36Months | number) + '%' : '_'}}
                              </span>
                              <a [href]="item.linkBuy" class="btn btn--blue btn--mua">{{'Mua' | translate}}</a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- No data  -->
          <div class="no-items-position justify-content-center" *ngIf="isCall && !products.length">
            <div class="no-items"></div>
            <span class="no-items-message">{{'Không có dữ liệu'  | translate}}</span>
          </div>
          <div class="d-flex justify-content-center mt-2 mb-2 btn-viewall" *ngIf="isCall && isViewSummary && !isGenerateImageUpload">
            <a [href]="Constant.url_login">{{'Xem tất cả'  | translate}} </a>
          </div>
        </div>
      </div>

      <!-- <p class="note-table required p-3">
        * {{'Xoay ngang màn hình để xem đầy đủ thông tin'}}
      </p> -->
    </div>
  </div>
</section>
