// Imports
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import { Constant } from '../../general/blog/constant';
import { ActivatedRoute } from '@angular/router';

// Component Decorator
@Component({
  selector: 'smart-portfolio',
  standalone: true,
  templateUrl: './smart-portfolio.component.html',
  styleUrls: ['./smart-portfolio.component.scss']
})

// Component Class
export class SmartPortfolioComponent implements OnInit {
  constructor(
    public meta: Meta,
    public title: Title,
    public route: ActivatedRoute
  ) {

  }
  ngOnInit(): void {
    this.setSEOMeta();
    const queryParams: any = this.route.snapshot.queryParams;
    const urlQueryParams = queryParams.type ? '?type=' + queryParams.type : '';
    if (typeof window !== 'undefined') {
      window.open('https://fmarket.vn/trade/account/investor/smart-portfolio' + urlQueryParams, '_self');
    }
  }
  setSEOMeta() {
    this.title.setTitle('RoboF - Danh mục đầu tư thông minh');
    this.meta.updateTag({ property: 'og:title', content: 'RoboF - Danh mục đầu tư thông minh ' });
    this.meta.updateTag({
      property: 'og:description',
      content: 'RoboF - Danh mục đầu tư thông minh ',
    });
    this.meta.updateTag({
      property: 'og:image',
      content: Constant.url_logo_fmarket_share,
    });
    this.meta.updateTag({
      name: 'keywords',
      content: `RoboF - Danh mục đầu tư thông minh `,
    });
    this.meta.updateTag({ property: 'og:url', content: environment.domain + Constant.path_smart_portfolio });
  }
}