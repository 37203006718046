import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NewPagingComponent } from './components/new-paging/new-paging.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { TableProducts } from '../pages/general/dashboard/table-products/table-products';
import { DropdownSearchComponent } from './components/dropdown-search/dropdown-search.component';
import { CatalogueComponent } from './components/catalogue/catalogue.component';
import { InputMoneyComponent } from './components/input-money/input-money';
import { InputNumberComponent } from './components/input-number/input-number';
import { TranslateLoader, TranslateModule, TranslateService, TranslateStore } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { httpTranslateLoader } from '../app.config';
import { ConfirmRouterPopupComponent } from './components/confirm-router-popup/confirm-router-popup.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: httpTranslateLoader,
          deps: [HttpClient]
      } 
    }),
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    NewPagingComponent,
    DropdownSearchComponent,
    CatalogueComponent,
    TableProducts,
    InputMoneyComponent, InputNumberComponent,
    ConfirmRouterPopupComponent,
  ],
  declarations: [
    NewPagingComponent,
    DropdownSearchComponent,
    CatalogueComponent,
    TableProducts,
    InputMoneyComponent, InputNumberComponent,
    ConfirmRouterPopupComponent,
  ],
  providers: [
    
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedCommonModule { }